<template>
  <div class="product-card no-hover hover-image" v-if="data">
    <router-link
      :to="{
        name: 'product',
        params: { id: data.id, link: data.link },
      }"
      class="product-card__image-container no-hover"
    >
      <div class="product-card__top">
        <div class="product-card__top-col product-card__top-col--left">
          <span class="product-card__tag product-card__tag--new" v-if="data.is_new"> Новинка </span>
          <span class="product-card__tag product-card__tag--discount" v-if="data.share && data.share.percent">
            -{{ data.share.percent }}%
          </span>
        </div>
        <div class="product-card__top-col product-card__top-col--right">
          <button
            :class="{ 'product-card__favorite--active': inFavorites }"
            type="button"
            class="product-card__favorite"
            :title="inFavorites ? 'Убрать из избранного' : 'В избранное'"
            @click.prevent="addToFavorites"
          >
            <IconComponent name="heart" />
          </button>
        </div>
      </div>
      <div class="ratio-container">
        <ImgComponent :img="data | item_img" class="product-card__image" />
      </div>
    </router-link>
    <div class="product-card__container">
      <div class="product-card__prices">
        <span class="product-card__price">
          {{ data.price | share_price(data) | formatPrice }}
        </span>
        <span v-if="data.share && data.share.percent" class="product-card__old-price">
          {{ data.price | formatPrice }}
        </span>
      </div>
      <router-link
        :to="{
          name: 'product',
          params: { id: data.id, link: data.link },
        }"
        class="product-card__content"
      >
        <span class="product-card__category" v-if="data.category && data.category.title">
          {{ data.category.title }}
        </span>
        <span v-if="data.title" :title="data.title" class="product-card__title">
          {{ data.title }}
        </span>
        <span class="product-card__weight" v-if="data.weight">{{ data.weight }} г</span>
      </router-link>
      <div class="product-card__bottom">
        <ProductCountChangeComponent :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import LoginModal from "components/modals/components/LoginModal.vue";
import IconComponent from "components/IconComponent.vue";
import ProductCountChangeComponent from "components/product/ProductCountChangeComponent.vue";
// import product from "../../mixins/product";

export default {
  name: "ProductCardComponent",
  props: {
    data: Object,
  },
  // mixins: [product],
  data() {
    return {
      debounceTimeout: null,
    };
  },
  computed: {
    inFavorites() {
      return this.$store.state.auth.favorites_ids.includes(this.data.id);
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
  methods: {
    async addToFavorites() {
      if (this.isRegistered) {
        let index = this.$store.state.auth.favorites_ids.indexOf(this.data.id);
        if (index !== -1) {
          this.$store.state.auth.favorites_ids.splice(index, 1);
        } else {
          this.$store.state.auth.favorites_ids.push(this.data.id);
        }
        await this.$store
          .dispatch("FAVORITE_CREATE_OR_DELETE", {
            apollo: this.$apollo,
            variables: {
              item_id: this.data.id,
            },
          })
          .then(() => {
            this.$store.dispatch("GET_FAVORITES", {
              apollo: this.$apollo,
            });
          });
      } else {
        this.showLoginModal();
      }
    },
    isProductNew(date) {
      let oneDay = 1000 * 60 * 60 * 24;
      return date ? Math.floor((Date.now() - new Date(date).getTime()) / oneDay) <= 14 : false;
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
  },
  components: { ProductCountChangeComponent, IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/product-card.styl"
</style>
