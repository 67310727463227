<template>
  <div
    class="slider"
    :class="{
      'slider--shadow': shadow,
      'slider--disable': disable,
      'slider--wrapper': wrapper,
      'slider--auto-height': autoHeight,
    }"
    v-if="items && items.length"
  >
    <div class="slider__header" v-if="title">
      <h3 class="slider__title">{{ title }}</h3>
      <IconComponent v-if="arrow" name="arrow-small-right-1" />
    </div>
    <div class="slider__items">
      <div class="slider__items-slides">
        <div class="swiper" ref="items">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in items" :key="i">
              <slot :item="item"></slot>
            </div>
          </div>
        </div>
      </div>
      <SliderArrowsComponent
        v-if="swiper && !hideArrows && canShowArrows"
        :isBeginning="swiper.isBeginning && !sliderOptions.loop"
        :isEnd="swiper.isEnd && !sliderOptions.loop"
        @left="swiper.slidePrev()"
        @right="swiper.slideNext()"
        :shiftArrows="shiftArrows"
      />
    </div>
  </div>
</template>

<script>
import SliderArrowsComponent from "components/slider/SliderArrowsComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SliderComponent",
  props: {
    title: String,
    buttonTitle: String,
    items: Array,
    hideArrows: Boolean,
    shiftArrows: Boolean,
    shadow: Boolean,
    disable: Boolean,
    arrow: Boolean,
    wrapper: Boolean,
    autoHeight: Boolean,
    route: {
      type: Object,
      default() {
        return {
          name: "",
        };
      },
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          slidesPerView: "auto",
          spaceBetween: 24,
          pagination: false,
          clickable: false,
        };
      },
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    canShowArrows() {
      let slidesPerView = this.getCurrentBreakpoint().slidesPerView;
      if (typeof slidesPerView === "string") {
        return slidesPerView === "auto";
      }
      if (typeof slidesPerView === "number") {
        return this.items.length > slidesPerView || !!this.sliderOptions.loop;
      }
      return false;
    },
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, this.sliderOptions);
    });
  },
  methods: {
    getCurrentBreakpoint() {
      if (this.sliderOptions.breakpoints && typeof window !== "undefined") {
        let returnCandidate = this.sliderOptions;
        Object.keys(this.sliderOptions.breakpoints).forEach((key) => {
          if (window.innerWidth > parseInt(key)) {
            returnCandidate = this.sliderOptions.breakpoints[key];
          }
        });
        return returnCandidate;
      }
      return this.sliderOptions;
    },
  },
  components: { IconComponent, SliderArrowsComponent },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  width 100%
  display flex
  text-align: center;
  font-size: 18px;
  //background: #fff;
  height: 100%
  justify-content: center;
  align-items: center;
}

.slider {
  display flex
  flex-direction: column;
  justify-content center
  align-items center
  //padding 0 15px
  height: 100%;
  --padding: 30px
  +below(860px) {
    --padding: 50px
  }

  &:not(&--shadow) {
    .slider__items-slides {
      border-radius var(--big-radius)
      overflow hidden
    }
  }

  &--shadow {
    .swiper {
      margin calc(var(--padding) * -1)
      padding var(--padding)
      width calc(100% + (var(--padding) * 2))
      border-radius var(--big-radius)
      overflow hidden
    }
  }

  &--disable {
    .swiper-wrapper {
      display flex
      flex-wrap wrap
      gap: var(--gap)
    }

    .swiper-slide {
      margin 0 !important
    }
  }

  &--auto-height {
    .swiper-slide {
      height 100%
    }
  }

  //&--grid {
  //  .swiper-slide {
  //    height: calc((100% - 30px) / 2) !important;
  //  }
  //}

  &__title {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 32px;
    color: var(--body-color);
  }

  &__items {
    position relative
    max-width var(--main-width)
    width 100%
    height 100%
  }

  &__header {
    max-width var(--main-width)
    display flex
    align-items center
    gap: 15px
    width 100%
    margin-bottom 30px
    z-index 2

    .icon {
      width 20px
      height 20px
    }
  }

  &__title {
    font-weight: 900;
    font-size: 1.75em;
    line-height: 34px;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    align-items: center;
    margin 0
  }
}
</style>
