<template>
  <div class="slider-arrows" :class="{ 'slider-arrows--shift': shiftArrows }">
    <button
      type="button"
      title="Влево"
      class="slider-arrows__item slider-arrows__item--left"
      :class="{ 'slider-arrows__item--hide': isBeginning }"
      @click="$emit('left')"
      :disabled="isBeginning"
    >
      <IconComponent name="arrow_left-1" />
    </button>
    <button
      type="button"
      title="Вправо"
      class="slider-arrows__item slider-arrows__item--right"
      :class="{ 'slider-arrows__item--hide': isEnd }"
      @click="$emit('right')"
      :disabled="isEnd"
    >
      <IconComponent name="arrow_right-1" />
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "SliderArrowsComponent",
  props: {
    isBeginning: Boolean,
    isEnd: Boolean,
    shiftArrows: Boolean,
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.slider-arrows {
  z-index: 1;
  +below(860px) {
    //display none
    display flex
    gap 15px
    justify-content center
    margin-top 20px
  }

  &--shift {
    +above(861px) {
      & ^[0]__item--right {
        transform translateX(calc(-50% - 5px))
      }

      & ^[0]__item--left {
        transform translateX(calc(50% + 5px))
      }
    }
  }

  &__item {
    z-index: 1;
    margin auto
    background: var(--white);
    border: 1px solid transparent
    box-sizing: border-box;
    border-radius: 40px;
    width 50px
    height 50px
    display flex
    align-items center
    justify-content center
    cursor pointer
    box-shadow: 0px 49px 20px rgba(0, 0, 0, 0.01), 0px 28px 17px rgba(0, 0, 0, 0.03), 0px 12px 12px rgba(0, 0, 0, 0.04), 0px 3px 7px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
    transition var(--transition)

    &:not([disabled]):hover {
      background var(--gray)

      .icon svg path {
        fill var(--main)
      }
    }

    &[disabled] {
      cursor default

      .icon svg path {
        fill var(--gray-100)
      }
    }

    &--hide {
      +above(861px) {
        display none
      }
    }

    .icon {
      width 8px
      height 8px

      svg path {
        fill var(--dark)
        transition var(--transition)
      }
    }

    &--left {
      absolute left -55px top bottom
      margin-left 0
      margin-right auto
      +below(860px) {
        position unset
        margin 0
      }
    }

    &--right {
      absolute right -55px top bottom
      margin-left auto
      margin-right 0
      +below(860px) {
        position unset
        margin 0
      }
    }
  }
}
</style>
