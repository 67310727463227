<template>
  <main class="thanks-page page">
    <BreadcrumbsComponent title="Заказ оформлен" />
    <div class="container-padding">
      <div class="thanks-page__inner">
        <div class="thanks-page__header">
          <div class="thanks-page__content">
            <div class="thanks-page__icon">
              <IconComponent name="verify" />
            </div>
            <span class="thanks-page__title">
              Ваш заказ №{{ params.id }}<br />
              от {{ params.created_at }} успешно создан.
            </span>
            <span class="thanks-page__subtitle">Номер Вашей оплаты {{ params.id }}/1</span>
          </div>
          <div class="thanks-page__content thanks-page__content--white">
            <span class="thanks-page__title">Просмотр заказов</span>
            <router-link :to="{ name: 'user', params: { link: 'orders' } }" class="btn btn--lg btn--main">
              Посмотреть заказы
            </router-link>
            <span class="thanks-page__subtitle thanks-page__subtitle--white">
              Вы можете следить за выполнением своего заказа в Персональном разделе сайта. Обратите внимание,
              что для входа в этот раздел вам необходимо будет ввести логин и пароль пользователя сайта.
            </span>
          </div>
        </div>
        <div class="thanks-page__bottom">
          <ProductsSliderComponent title="Новинки" :items="products" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import THANK_YOU_PAGE from "@/graphql/pages/ThankYouPage.graphql";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import ProductsSliderComponent from "components/slider/ProductsSliderComponent.vue";

// переделать численные параметры
function parseParams(query) {
  Object.keys(query).forEach((key) => {
    try {
      query[key] = JSON.parse(query[key]);
    } catch (e) {
      return null;
    }
  });
  return query;
}

export default {
  name: "ThankYouPage",
  async asyncData({ res, apollo, store }) {
    await apollo.defaultClient
      .query({
        query: THANK_YOU_PAGE,
      })
      .then(({ data }) => {
        store.state.page_thanks.products = data.products_paginate.data || [];
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
      })
      .catch(() => {});
    if (res) {
      res.status(404);
    }
  },
  data() {
    return {
      params: {},
    };
  },
  created() {
    this.params = this.parseParams(this.$route.query);
  },
  computed: {
    products() {
      return this.$store.state.page_thanks.products;
    },
  },
  methods: {
    parseParams,
  },
  components: {
    ProductsSliderComponent,
    IconComponent,
    BreadcrumbsComponent,
  },
  metaInfo: {
    title: "Заказ оформлен",
    titleTemplate: null,
  },
};
</script>

<style lang="stylus">
.thanks-page {

  &__inner {
    display flex
    flex-direction column
    gap 50px
  }

  &__header {
    background var(--gray)
    border-radius 20px
    padding 30px
    display grid
    grid-template-columns 620fr 560fr
    +below(800px) {
      grid-template-columns 1fr
      grid-gap 30px
    }
  }

  &__content {
    display flex
    flex-direction column
    align-items center
    text-align center
    gap 30px

    &--white {
      border-top 1px solid var(--gray-3)
      border-radius 20px
      background var(--white)
      padding 30px
    }
  }

  &__icon {
    display flex
    padding 30px
    border 1px solid var(--gray-3)
    border-radius 100px
    background var(--white)

    .icon svg {
      max-width 80px
      max-height 80px
    }
  }

  &__title {
    font-size 1.25rem
    font-weight 700
    line-height normal
  }

  &__subtitle {
    font-size 1.125rem
    line-height 28px
    color var(--dark-2)

    &--white {
      font-size 1rem
    }
  }
}
</style>
