var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',{staticClass:"product-card no-hover hover-image"},[_c('router-link',{staticClass:"product-card__image-container no-hover",attrs:{"to":{
      name: 'product',
      params: { id: _vm.data.id, link: _vm.data.link },
    }}},[_c('div',{staticClass:"product-card__top"},[_c('div',{staticClass:"product-card__top-col product-card__top-col--left"},[(_vm.data.is_new)?_c('span',{staticClass:"product-card__tag product-card__tag--new"},[_vm._v(" Новинка ")]):_vm._e(),(_vm.data.share && _vm.data.share.percent)?_c('span',{staticClass:"product-card__tag product-card__tag--discount"},[_vm._v("\n          -"+_vm._s(_vm.data.share.percent)+"%\n        ")]):_vm._e()]),_c('div',{staticClass:"product-card__top-col product-card__top-col--right"},[_c('button',{staticClass:"product-card__favorite",class:{ 'product-card__favorite--active': _vm.inFavorites },attrs:{"type":"button","title":_vm.inFavorites ? 'Убрать из избранного' : 'В избранное'},on:{"click":function($event){$event.preventDefault();return _vm.addToFavorites.apply(null, arguments)}}},[_c('IconComponent',{attrs:{"name":"heart"}})],1)])]),_c('div',{staticClass:"ratio-container"},[_c('ImgComponent',{staticClass:"product-card__image",attrs:{"img":_vm._f("item_img")(_vm.data)}})],1)]),_c('div',{staticClass:"product-card__container"},[_c('div',{staticClass:"product-card__prices"},[_c('span',{staticClass:"product-card__price"},[_vm._v("\n        "+_vm._s(_vm._f("formatPrice")(_vm._f("share_price")(_vm.data.price,_vm.data)))+"\n      ")]),(_vm.data.share && _vm.data.share.percent)?_c('span',{staticClass:"product-card__old-price"},[_vm._v("\n        "+_vm._s(_vm._f("formatPrice")(_vm.data.price))+"\n      ")]):_vm._e()]),_c('router-link',{staticClass:"product-card__content",attrs:{"to":{
        name: 'product',
        params: { id: _vm.data.id, link: _vm.data.link },
      }}},[(_vm.data.category && _vm.data.category.title)?_c('span',{staticClass:"product-card__category"},[_vm._v("\n        "+_vm._s(_vm.data.category.title)+"\n      ")]):_vm._e(),(_vm.data.title)?_c('span',{staticClass:"product-card__title",attrs:{"title":_vm.data.title}},[_vm._v("\n        "+_vm._s(_vm.data.title)+"\n      ")]):_vm._e(),(_vm.data.weight)?_c('span',{staticClass:"product-card__weight"},[_vm._v(_vm._s(_vm.data.weight)+" г")]):_vm._e()]),_c('div',{staticClass:"product-card__bottom"},[_c('ProductCountChangeComponent',{attrs:{"data":_vm.data}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }