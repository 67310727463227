<template>
  <div v-if="items && items.length" class="product-slider wrapper">
    <SliderComponent
      :items="items"
      v-slot="{ item }"
      :slider-options="productSliderOptions"
      :title="title"
      autoHeight
      shadow
      arrow
    >
      <ProductCardComponent :data="item" />
    </SliderComponent>
  </div>
</template>

<script>
import SliderComponent from "components/slider/SliderComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";

export default {
  name: "ProductsSliderComponent",
  props: {
    items: Array,
    title: String,
  },
  data() {
    return {
      productSliderOptions: {
        slidesPerView: 1.5,
        spaceBetween: 15,
        pagination: false,
        clickable: false,
        preloadImages: true,
        watchOverflow: true,
        autoHeight: true,
        calculateHeight: true,
        breakpoints: {
          580: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
      },
    };
  },
  components: { ProductCardComponent, SliderComponent },
};
</script>
