<template>
  <div
    v-if="count || focused || isCart"
    class="product-count"
    :class="{ 'product-count--product-page': isProductPage }"
  >
    <button @click.prevent="minus" class="btn btn--md btn--main">
      <IconComponent name="minus" />
    </button>
    <InputComponent
      @focus="focused = true"
      @blur="focused = false"
      @input="change"
      @keydown.109.189.187.prevent
      class="product-count__input"
      v-model.trim.number="count"
      :min="min"
      :max="max"
      type="number"
      no-arrows
    />
    <button @click.prevent="plus" class="btn btn--md btn--main">
      <IconComponent name="plus" />
    </button>
  </div>
  <button v-else @click.prevent="plus" type="button" class="btn btn--main btn--md">
    <IconComponent name="bag-2" />
    <span>В корзину</span>
  </button>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import { addCartItems, removeCartItems } from "@/utils";

export default {
  name: "ProductCountChangeComponent",
  components: { InputComponent, IconComponent },
  props: {
    data: Object,
    isCart: Boolean,
    isProductPage: Boolean,
  },
  data() {
    return {
      count: 0,
      min: 1,
      max: 50,
      focused: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.count = this.inCartCount;
    });
  },
  watch: {
    "$store.state.auth.cart"() {
      this.count = this.inCartCount;
    },
  },
  computed: {
    // количество данного товара в корзине
    inCartCount() {
      return this.itemInCart ? this.itemInCart?.count : null;
    },
    itemInCart() {
      return this.data && this.$store.state.auth.cart
        ? this.$store.state.auth.cart.find((c) => c.item?.id === this.data?.id)
        : {};
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
  methods: {
    addCartItems,
    removeCartItems,
    remove() {
      if (this.itemInCart && (this.itemInCart.id || this.itemInCart.item.id)) {
        let ids = this.itemInCart.id ? [this.itemInCart.id] : [this.itemInCart.item.id];
        this.removeCartItems({ store: this.$store, apollo: this.$apollo.provider.defaultClient, ids });
      }
    },
    minus() {
      if (this.count && this.count > this.min) {
        this.count -= 1;
        this.addCartItems({
          store: this.$store,
          apollo: this.$apollo.provider.defaultClient,
          data: this.data,
          count: this.count,
        });
      } else {
        this.remove();
      }
    },
    plus() {
      if (this.count && this.count >= this.max) {
        this.count = this.max;
      } else {
        this.count += 1;
        this.addCartItems({
          store: this.$store,
          apollo: this.$apollo.provider.defaultClient,
          data: this.data,
          count: this.count,
        });
      }
    },
    change() {
      if (this.count && this.count >= this.max) {
        this.count = this.max;
        this.addCartItems({
          store: this.$store,
          apollo: this.$apollo.provider.defaultClient,
          data: this.data,
          count: this.count,
        });
      } else if (this.count < this.min) {
        this.count = this.min;
        this.addCartItems({
          store: this.$store,
          apollo: this.$apollo.provider.defaultClient,
          data: this.data,
          count: this.count,
        });
      } else {
        this.addCartItems({
          store: this.$store,
          apollo: this.$apollo.provider.defaultClient,
          data: this.data,
          count: this.count,
        });
      }
      // if (this.count && this.count > 0) {
      //   // добавление в корзину
      //   this.addCartItems({
      //     store: this.$store,
      //     apollo: this.$apollo.provider.defaultClient,
      //     data: this.data,
      //     count: this.count,
      //   });
      // } else {
      //   this.remove();
      //   this.focused = false;
      // }
    },
  },
};
</script>

<style lang="stylus">
.product-count {
  display flex
  justify-content center
  gap: 5px

  &--product-page {
    justify-content flex-start

    .product-count__input {
      .input__container {
        border-color var(--gray-dark)
      }
    }
  }

  &__input {
    width 68px
    height 50px
    min-width 50px

    .input__container {
      background var(--gray)
      border-color var(--gray)

      &:hover
      &:focus-within {
        border-color var(--main)
      }
    }

    .input__field {
      border-color: transparent
      text-align center
      transition border-color var(--transition)
      padding 10px
    }
  }

  .btn {
    width: 50px
    height: 50px
    flex-shrink 0
  }
}
</style>
